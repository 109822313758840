import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressCard,
  faAngleDoubleRight,
  faCheck,
  faCodeBranch,
  faComments,
  faFingerprint,
  faHeadphonesAlt,
  faGlassCheers,
  faIdCardAlt,
  faMicroscope,
  faPaperPlane,
  faRocket,
  faRunning,
  faSchool,
  faTimes,
  faTools,
  faUmbrellaBeach,
  faUserSecret,
  faUserGraduate
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedinIn,
  faLinkedin,
  faSpotify,
  faStrava
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faFileAlt,
  faUserCircle
} from "@fortawesome/free-regular-svg-icons";

const GlobalIcons = {
  install(app) {
    app.component("FontAwesomeIcon", FontAwesomeIcon);
    library.add(
      faAddressCard,
      faAngleDoubleRight,
      faCheck,
      faCodeBranch,
      faComments,
      faEnvelope,
      faFileAlt,
      faFingerprint,
      faHeadphonesAlt,
      faGlassCheers,
      faIdCardAlt,
      faLinkedin,
      faLinkedinIn,
      faMicroscope,
      faPaperPlane,
      faRocket,
      faRunning,
      faSchool,
      faSpotify,
      faStrava,
      faTimes,
      faTools,
      faUmbrellaBeach,
      faUserCircle,
      faUserGraduate,
      faUserSecret
    );
  }
};

export default GlobalIcons;
