import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Plugins for Tymezone Website
import plugins from "./plugins/websitePlugins";

// Create app instance
const appInstance = createApp(App);

// Plugin setup
appInstance.use(router);
appInstance.use(plugins);

appInstance.mount("#app");
