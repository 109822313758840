<template>
  <div :class="inputMarginClass">
    <slot name="label">
      <label v-if="label" class="form-label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <div
      :class="[
        {
          'has-danger': !!errorMessage,
          'has-success': meta.valid && meta.validated && meta.dirty,
          'input-group': hasIcon,
          'input-group-focus': focused,
          'has-label': label || $slots.label
        },
        inputGroupClasses
      ]"
      class="position-relative"
    >
      <span v-if="addonLeftIcon || $slots.addonLeft" class="input-group-text">
        <slot name="addonLeft">
          <font-awesome-icon
            fixed-width
            :icon="[`${faLibrary}`, `${faIcon}`]"
            :class="addonLeftIcon"
          />
        </slot>
      </span>

      <slot>
        <input
          :id="name"
          class="form-control"
          :name="name"
          :type="type"
          :placeholder="placeholder"
          :required="required"
          :disabled="disabled"
          :value="inputValue"
          :class="inputClasses"
          @input="handleChange"
          @blur="focused = false"
          @focus="focused = true"
        />
        <font-awesome-icon
          v-if="meta.valid && meta.validated && meta.dirty"
          class="fa-valicon fa-success"
          :icon="['fas', 'check']"
        ></font-awesome-icon>
        <font-awesome-icon
          v-if="!!errorMessage"
          class="fa-valicon fa-danger"
          :icon="['fas', 'times']"
        ></font-awesome-icon>
      </slot>
      <span v-if="addonRightIcon || $slots.addonRight" class="input-group-text">
        <slot name="addonRight">
          <font-awesome-icon
            fixed-width
            :class="addonRightIcon"
            :icon="[`${faLibrary}`, `${faIcon}`]"
          />
        </slot>
      </span>
      <slot name="infoBlock"></slot>
      <slot name="helpBlock">
        <div
          v-show="errorMessage"
          class="text-danger invalid-feedback"
          style="display: block"
          :class="{ 'mt-2': hasIcon }"
        >
          {{ errorMessage }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  name: "BasicInput",
  props: {
    addonRightIcon: {
      type: String,
      default: ""
    },
    addonLeftIcon: {
      type: String,
      default: ""
    },
    faLibrary: {
      type: String,
      default: ""
    },
    faIcon: {
      type: String,
      default: ""
    },
    append: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: "text"
    },
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: ""
    },
    inputGroupClasses: {
      type: String,
      default: ""
    },
    group: {
      type: String,
      default: ""
    },
    inputClasses: {
      type: String,
      default: ""
    },
    inputMarginClass: {
      type: String,
      default: "mb-3"
    }
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      meta
    } = useField(props.name, undefined, {
      initialValue: props.value
    });

    return {
      handleChange,
      errorMessage,
      inputValue,
      meta
    };
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== null && addonRight !== "" && addonRight !== undefined,
        addonLeft !== null && addonLeft !== "" && addonLeft !== undefined,
        this.addonRightIcon !== null &&
          this.addonRightIcon !== "" &&
          this.addonRightIcon !== undefined,
        this.addonLeftIcon !== null &&
          this.addonLeftIcon !== "" &&
          this.addonLeftIcon !== undefined
      );
    }
  }
};
</script>

<style></style>
