<template>
  <div>
    <div class="page-header page-header-small clear-filter" filter-color="red">
      <parallax-effect
        class="page-header-image"
        style="
          background-image: url(&quot;img/backgrounds/contact-header.webp&quot;);
        "
      />
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ms-auto me-auto">
            <font-awesome-icon
              id="contact-icon"
              icon="paper-plane"
              class="fs-1"
            />
            <h2 class="title text-uppercase fw-light mt-4">
              Let's get in touch
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="main bg-white position-relative">
      <div class="contact-content pt-6 pb-5">
        <div class="find-me pb-5">
          <div class="container">
            <div class="row">
              <div class="col-md-8 me-auto ms-auto">
                <h2 class="title text-center">Find me online</h2>
                <p class="description text-center">
                  I'm not fond of social media so you won't find me there.
                  However, if you want to listen to the music I like or want to
                  impress me with your workouts, use the buttons below to
                  connect.
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <info-section
                  direction="vertical"
                  hover
                  link="https://open.spotify.com/user/9j0pym1dxywwai25a0bevqwqc?si=KI9WSFsgQTuXqWiyDMjB4w"
                  icon-classes="icon-circle"
                  fa-library="fab"
                  fa-icon="spotify"
                  type="success"
                  icon="spotify"
                  title="Follow me on Spotify"
                  description="Come listen to my tunes."
                />
              </div>

              <div class="col-md-4">
                <info-section
                  direction="vertical"
                  hover
                  link="https://www.linkedin.com/in/thijmenduijve/"
                  icon-classes="icon-circle"
                  fa-library="fab"
                  fa-icon="linkedin"
                  type="info"
                  icon="linkedin"
                  title="Connect on LinkedIn"
                  description="For serious business."
                />
              </div>

              <div class="col-md-4">
                <info-section
                  direction="vertical"
                  hover
                  link="https://www.strava.com/athletes/21586760"
                  icon-classes="icon-circle"
                  fa-library="fab"
                  fa-icon="strava"
                  type="primary"
                  icon="strava"
                  title="Challenge me on Strava"
                  description="Let's see what you got."
                />
              </div>
            </div>
          </div>
        </div>
        <div class="separator-line separator-primary" />
        <div class="message-me pt-5 pb-5">
          <div class="container">
            <div class="row">
              <div class="col-md-7 ms-auto me-auto">
                <h2 class="title text-center">Send me a message</h2>
                <p class="description text-center">
                  Leave your details in the contact form below and I'll get in
                  touch!
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-5 ms-auto me-auto mt-4">
                <VeeForm
                  v-slot="{ handleSubmit }"
                  :validation-schema="schema"
                  as="div"
                >
                  <form
                    id="contact-form"
                    ref="form"
                    @submit="handleSubmit($event, contactMe)"
                  >
                    <basic-input
                      name="name"
                      type="text"
                      label="Your name"
                      input-classes="form-control-lg"
                      placeholder="Name goes here..."
                      addon-left-icon="fa-icon"
                      fa-icon="user-circle"
                      fa-library="far"
                    />
                    <basic-input
                      name="email"
                      type="text"
                      label="Your email"
                      input-classes="form-control-lg"
                      placeholder="A valid email address..."
                      addon-left-icon="fa-icon"
                      fa-icon="envelope"
                      fa-library="far"
                    />

                    <div class="mb-4">
                      <label class="form-label"> Your message </label>
                      <textarea
                        id="message"
                        v-model="message"
                        placeholder="Write some nice stuff or nothing at all..."
                        name="message"
                        class="form-control"
                        rows="6"
                      />
                    </div>
                    <div class="mb-4">
                      <vue-recaptcha
                        ref="vueRecaptcha"
                        sitekey="6LeWdKIoAAAAAAVDKI7HXARjWNJTn0h9ladDb8CZ"
                        size="normal"
                        theme="light"
                        :tabindex="0"
                        @verify="recaptchaVerified"
                        @expire="recaptchaExpired"
                        @fail="recaptchaFailed"
                      ></vue-recaptcha>
                    </div>
                    <div class="submit text-center">
                      <!-- ADD BUTTON BLOCK -->
                      <div class="d-grid">
                        <basic-button type="primary" native-type="submit" round>
                          Submit
                        </basic-button>
                      </div>
                    </div>
                  </form>
                </VeeForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Components
import InfoSection from "@/components/InfoSection";

// Form
import vueRecaptcha from "vue3-recaptcha2";
import { Form as VeeForm } from "vee-validate";
import * as Yup from "yup";

// API
import axios from "axios";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

export default {
  name: "ContactView",
  bodyClass: "contact-page",
  components: {
    InfoSection,
    vueRecaptcha,
    VeeForm
  },
  setup(values) {
    const schema = Yup.object().shape({
      name: Yup.string().required("A name is required."),
      email: Yup.string()
        .email("A valid email address is required.")
        .required("A valid email address is required.")
    });

    var robot = false;
    var message = "";

    return {
      values,
      schema,
      robot,
      message
    };
  },
  methods: {
    contactMe(values, { resetForm }) {
      // If robot is true
      if (this.robot) {
        // Send pushover notification
        console.log("Sending Pushover notification");

        var msg =
          "You received a message from: " +
          values.name +
          " (" +
          values.email +
          "). Message: " +
          this.message;

        var pushover = {
          token: process.env.VUE_APP_PUSHOVER_APPTOKEN,
          user: process.env.VUE_APP_PUSHOVER_USERKEY,
          message: msg
        };

        axios.post("https://api.pushover.net/1/messages.json", pushover);

        // Send email notification
        console.log("Sending EmailJS notification");
        emailjs
          .sendForm(
            process.env.VUE_APP_EMAILJS_SERVICEKEY,
            "template_q4itria",
            this.$refs.form,
            process.env.VUE_APP_EMAILJS_PUBLICKEY
          )
          .then(
            (result) => {
              console.log("SUCCESS!", result.status, result.text);
            },
            (error) => {
              console.log("FAILED...", error);
            }
          );

        Swal.fire({
          icon: "success",
          title: "Message received!",
          text: "I'll get in touch."
        });

        // Reset form field
        this.message = "";
        resetForm();

        // If robot is false
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Prove me you're not a robot!"
        });
      }
    },
    // Set robot to true
    recaptchaVerified(response) {
      if (response) this.robot = true;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {}
  }
};
</script>
<style></style>
