// Create Router
import { createRouter, createWebHistory } from "vue-router";

// Views
import IndexView from "../views/IndexView.vue";
import ProfileView from "../views/ProfileView.vue";
import ContactView from "../views/ContactView.vue";
import NotFoundView from "../views/NotFoundView.vue";

// Header & footer
import MainNavbar from "../views/layout/MainNavbar.vue";
import MainFooter from "../views/layout/MainFooter.vue";

const routes = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: "/index",
    name: "index",
    components: { default: IndexView, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: "black" }
    }
  },
  {
    path: "/profile",
    name: "profile",
    components: {
      default: ProfileView,
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: "dark-blue" }
    }
  },
  {
    path: "/contact",
    name: "contact",
    components: {
      default: ContactView,
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: "black" }
    }
  },
  { path: "/:pathMatch(.*)*", component: NotFoundView }
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash };
    }
  },
  routes
});

export default router;
