<template>
  <div class="page-header header-filter" filter-color="orange">
    <parallax-effect
      class="page-header-image"
      style="background-image: url(&quot;img/backgrounds/header.webp&quot;)"
    />
    <div class="container">
      <div class="content-center brand">
        <img class="tymezone-logo" src="img/tymezone-logo.png" alt="" />
        <h1 class="h1-seo mt-4">Welcome to my Tymezone!</h1>
        <p>My personal space on the world wide web.</p>
      </div>
      <h6 class="category category-absolute">
        01110011 01101101 01100001 01110010 01110100 01111001 00100000 01110000
        01100001 01101110 01110100 01110011
      </h6>
    </div>
  </div>
</template>
<script>
export default {
  name: "IndexView",
  bodyClass: "index-page"
};
</script>
<style></style>
