// Cards
import CardComponent from "@/components/Cards/CardComponent";

// Inputs
import BasicInput from "@/components/Inputs/BasicInput";

// Navbar
import NavBar from "@/components/NavBar/NavBar";

// Rest
import BasicButton from "@/components/BasicButton";
import DropDown from "@/components/DropDown";
import ParallaxEffect from "@/components/ParallaxEffect";

const GlobalComponents = {
  install(app) {
    app.component("BasicInput", BasicInput);
    app.component("BasicButton", BasicButton);
    app.component("CardComponent", CardComponent);
    app.component("DropDown", DropDown);
    app.component("NavBar", NavBar);
    app.component("ParallaxEffect", ParallaxEffect);
  }
};

export default GlobalComponents;
