<template>
  <li :class="{ 'timeline-inverted': inverted }">
    <div v-if="$slots.icon" class="timeline-badge-icon" :class="badgeType">
      <slot name="icon" />
    </div>
    <div v-if="$slots.logo" class="timeline-badge-logo">
      <slot name="logo" />
    </div>
    <div class="timeline-panel">
      <div class="timeline-heading">
        <slot name="header" />
      </div>
      <div v-if="$slots.content" class="timeline-body">
        <slot name="content" />
      </div>
      <h6 v-if="$slots.footer">
        <slot name="footer" />
      </h6>
    </div>
  </li>
</template>
<script>
export default {
  name: "TimeLineItem",
  props: {
    inverted: Boolean,
    badgeType: {
      type: String,
      default: "success"
    }
  }
};
</script>
<style></style>
