<template>
  <div
    class="info"
    :class="[`info-${direction}`, { ['info-hover']: `${hover}` }]"
  >
    <a :href="`${link}`" target="_blank">
      <div
        v-if="$slots.icon || icon"
        class="icon"
        :class="[`icon-${type}`, iconClasses]"
      >
        <slot name="icon">
          <font-awesome-icon
            :icon="[`${faLibrary}`, `${faIcon}`]"
            class="align-middle"
          />
        </slot>
      </div>
    </a>
    <div v-if="$slots.default || title" class="description">
      <slot>
        <h4 class="info-title">
          {{ title }}
        </h4>
        <p class="description text-secondary">
          {{ description }}
        </p>
      </slot>
    </div>
    <slot name="raw-content" />
  </div>
</template>
<script>
export default {
  props: {
    direction: {
      type: String,
      default: "horizontal"
    },
    hover: Boolean,
    link: {
      type: String,
      default: "#"
    },
    faLibrary: {
      type: String,
      default: ""
    },
    faIcon: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    badgeClasses: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    iconClasses: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    }
  }
};
</script>
