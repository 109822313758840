<template>
  <nav-bar
    id="nav"
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ms-auto"
  >
    <router-link class="navbar-brand" to="/"> Tymezone </router-link>

    <template #navbar-menu>
      <drop-down
        tag="li"
        title="Who am I?"
        class="nav-item"
        icon="fa-icon"
        fa-library="fas"
        fa-icon="address-card"
        type="default"
      >
        <router-link class="dropdown-item" to="/profile">
          <font-awesome-icon
            class="fa-icon"
            size="lg"
            :icon="['far', 'user-circle']"
            fixed-width
          />
          <p>About Me</p>
        </router-link>
        <router-link class="dropdown-item" to="/profile#resume">
          <font-awesome-icon
            class="fa-icon"
            size="lg"
            :icon="['far', 'file-alt']"
            fixed-width
          />
          <p>My Resume</p>
        </router-link>
        <router-link class="dropdown-item" to="/profile#interests">
          <font-awesome-icon
            class="fa-icon"
            size="lg"
            :icon="['fas', 'umbrella-beach']"
            fixed-width
          />
          <p>Interests</p>
        </router-link>
        <router-link class="dropdown-item" to="/profile#spotify">
          <font-awesome-icon
            class="fa-icon"
            size="lg"
            :icon="['fas', 'headphones-alt']"
            fixed-width
          />
          <p>Spotify Corner</p>
        </router-link>
      </drop-down>
      <li class="nav-item">
        <router-link class="nav-link" to="/contact">
          <font-awesome-icon
            class="fa-icon"
            size="lg"
            icon="comments"
            fixed-width
          />
          <p>Contact me</p>
        </router-link>
      </li>
      <li class="nav-item">
        <a class="nav-link btn btn-neutral" href="https://toolbox.tymezone.nl/">
          <font-awesome-icon
            class="fa-icon"
            size="lg"
            icon="user-secret"
            fixed-width
          />
          <p>My Private Zone</p>
        </a>
      </li>
    </template>
  </nav-bar>
</template>

<script>
export default {
  name: "MainNavbar",
  props: {
    transparent: Boolean,
    colorOnScroll: {
      type: Number,
      default: 400
    }
  }
};
</script>

<style scoped></style>
