<template>
  <component
    :is="tag"
    v-click-outside="closeDropDown"
    class="dropdown"
    :class="[
      { show: isOpen },
      { dropdown: direction === 'down' },
      { dropup: direction === 'up' }
    ]"
    aria-haspopup="true"
    :aria-expanded="isOpen"
    @click="toggleDropDown"
  >
    <slot name="title">
      <a
        class="dropdown-toggle nav-link"
        :class="{ 'no-caret': hideArrow }"
        data-toggle="dropdown"
      >
        <font-awesome-icon
          :class="icon"
          :icon="[`${faLibrary}`, `${faIcon}`]"
          size="lg"
        />
        <span class="no-icon">{{ title }}</span>
      </a>
    </slot>
    <ul
      class="dropdown-menu"
      :class="[
        { 'dropdown-menu-right': position === 'right' },
        { show: isOpen },
        `dropdown-${type}`
      ]"
    >
      <slot></slot>
    </ul>
  </component>
</template>
<script>
export default {
  name: "DropDown",
  provide() {
    return {
      closeDropDown: this.closeDropDown
    };
  },
  props: {
    direction: {
      type: String,
      default: "down"
    },
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    faLibrary: {
      type: String,
      default: ""
    },
    faIcon: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "default",
      validator: (value) => {
        return [
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info"
        ].includes(value);
      }
    },
    position: {
      type: String,
      default: ""
    },
    hideArrow: Boolean,
    tag: {
      type: String,
      default: "li"
    }
  },
  emits: ["change"],
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
      this.$emit("change", this.isOpen);
    },
    closeDropDown() {
      this.isOpen = false;
      this.$emit("change", this.isOpen);
    }
  }
};
</script>
<style></style>
