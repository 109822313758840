<template>
  <div>
    <div class="page-header page-header-small clear-filter" filter-color="red">
      <parallax-effect
        class="page-header-image"
        style="
          background-image: url(&quot;img/backgrounds/palms-sundown.webp&quot;);
        "
      />
      <div class="container">
        <div class="avatar-container">
          <img src="img/avatar.png" alt="" />
        </div>
        <h2 class="mt-4 mb-2 text-center">Thijmen Duijve</h2>
        <p class="category text-center mb-1">Tech, music &amp; sports geek</p>
        <p class="sub-category text-center">
          Based in Amersfoort, the Netherlands
          <img class="flag" src="img/flags/NL.png" />
        </p>
      </div>
    </div>

    <div class="section section-aboutme">
      <div class="container">
        <div class="button-container">
          <a
            href="https://www.linkedin.com/in/thijmenduijve/"
            class="btn btn-primary btn-round btn-lg"
          >
            <font-awesome-icon
              :icon="['fab', 'linkedin-in']"
              size="lg"
              fixed-width
            />&nbsp;&nbsp;Connect
          </a>
        </div>
        <h3 class="title text-center mt-4">About me</h3>
        <div class="row justify-content-md-center">
          <div class="col col-md-10">
            <p class="description text-center mb-5">
              My name is Thijmen Duijve and I sprang to life in 1986, the year
              of Top Gun, Chernobyl and Mad Cow Disease. Nice to know that good
              things happened too right? When I'm not working, I like building
              things in the real world and the virtual world. Other things about
              me: occasional carnivore and enjoyer of whiskey tastings, wine
              tastings and beer festivals. I like solving complex puzzles but
              you may also wake me up for a pub quiz with family and friends
              anytime! <br />
              Want to know more about what keeps me going? Use your finger or
              mouse wheel and find out!
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="resume" class="section section-resume">
      <div class="container">
        <h3 class="title text-center pt-5 mb-4">My Resume</h3>
        <div class="col-md-12">
          <time-line>
            <time-line-item badge-type="info">
              <template #logo>
                <img src="img/logos/grabowsky-blue-shield.png" />
              </template>
              <template #header>
                <div class="badge-grabowsky">
                  <h6>
                    <font-awesome-icon icon="fingerprint" fixed-width />2020-now
                  </h6>
                  <h5 class="badge-title">
                    Practice Lead Okta &amp; ForgeRock
                  </h5>
                </div>
              </template>
              <template #content>
                <p>
                  In my current position as Practice Lead at Grabowsky, I'm
                  leading a team of Okta &amp; ForgeRock experts. Our objective
                  is to help organizations with effectively managing their
                  (digital) workforce and customer identities, by providing easy
                  and secure access to applications, websites and mobile apps.
                </p>
              </template>
            </time-line-item>

            <time-line-item inverted badge-type="info">
              <template #logo>
                <img src="img/logos/grabowsky-blue-shield.png" />
              </template>
              <template #header>
                <div class="badge-grabowsky">
                  <h6>
                    <font-awesome-icon
                      icon="id-card-alt"
                      fixed-width
                    />2017-2020
                  </h6>
                  <h5 class="badge-title">IAM Consultant</h5>
                </div>
              </template>
              <template #content>
                <p>
                  As an Identity &amp; Access Management Consultant at
                  Grabowsky, I was responsible for the design and implementation
                  of identity management solutions (mainly SailPoint) at our
                  clients. My primary focus was on aligning the technical
                  solution with the needs of the business.
                </p>
              </template>
            </time-line-item>

            <time-line-item badge-type="danger">
              <template #logo>
                <img src="img/logos/ggd-amsterdam.png" />
              </template>
              <template #header>
                <div class="badge-ggd">
                  <h6>
                    <font-awesome-icon
                      icon="code-branch"
                      fixed-width
                    />2014-2017
                  </h6>
                  <h5 class="badge-title">Developer &amp; IT auditor</h5>
                </div>
              </template>
              <template #content>
                <p>
                  As a developer I've build web apps for the public health
                  service (GGD) of the city of Amsterdam. The apps assisted the
                  healthcare professionals in helping their clients. The
                  projects followed an agile approach, where I was responsible
                  for:
                </p>
                <ul class="fa-ul">
                  <li>
                    <span class="fa-li">
                      <font-awesome-icon
                        :icon="['fas', 'angle-double-right']"
                      />
                    </span>
                    Information analysis and design
                  </li>
                  <li>
                    <span class="fa-li">
                      <font-awesome-icon
                        :icon="['fas', 'angle-double-right']"
                      />
                    </span>
                    Building &amp; testing
                  </li>
                  <li>
                    <span class="fa-li">
                      <font-awesome-icon
                        :icon="['fas', 'angle-double-right']"
                      />
                    </span>
                    Deployment and administration
                  </li>
                </ul>
                <p>
                  I also participated in achieving an ISO 27001 certificate for
                  the IT department and was responsible for conducting internal
                  IT audits.
                </p>
              </template>
            </time-line-item>

            <time-line-item inverted badge-type="success">
              <template #logo>
                <img src="img/logos/consumentenbond.png" />
              </template>
              <template #header>
                <div class="badge-consumentenbond">
                  <h6>
                    <font-awesome-icon icon="microscope" fixed-width />2011-2014
                  </h6>
                  <h5 class="badge-title">Researcher &amp; writer</h5>
                </div>
              </template>
              <template #content>
                <p>
                  After writing my master thesis at the Consumentenbond (Dutch
                  Consumer Union), I started working there as a researcher and a
                  writer. My tasks where:
                </p>
                <ul class="fa-ul">
                  <li>
                    <span class="fa-li">
                      <font-awesome-icon
                        :icon="['fas', 'angle-double-right']"
                      />
                    </span>
                    Developing key performance indicators that measured the
                    quality of health care at Dutch hospitals for conditions
                    with high prevalence.
                  </li>
                  <li>
                    <span class="fa-li">
                      <font-awesome-icon
                        :icon="['fas', 'angle-double-right']"
                      />
                    </span>
                    Publishing the quality data in a benchmark tool on the
                    website of the Consumentenbond. The tool provides comparison
                    information to consumers about the quality of care in Dutch
                    hospitals.
                  </li>
                  <li>
                    <span class="fa-li">
                      <font-awesome-icon
                        :icon="['fas', 'angle-double-right']"
                      />
                    </span>
                    Writing articles on various health related topics. The
                    articles were published in the Consumentengids (consumer
                    magazine) and the Gezondgids (special interest magazine).
                  </li>
                </ul>
              </template>
            </time-line-item>

            <time-line-item badge-type="info">
              <template #logo>
                <img src="img/logos/gamma.png" />
              </template>
              <template #header>
                <div class="badge-gamma">
                  <h6>
                    <font-awesome-icon icon="tools" fixed-width />2003-2011
                  </h6>
                  <h5 class="badge-title">DIY guru</h5>
                </div>
              </template>
              <template #content>
                <p>
                  GAMMA is a Dutch hardware store-chain. I started out as a
                  simple shelve restocker, but in the end I was partly
                  responsible for running the service desk (processing orders,
                  handling complaints, etc.) . My employment at the GAMMA helped
                  me pay for college and to enjoy the occasional beer with my
                  fellow students and friends. I cherish happy memories from my
                  time there: it's where I discovered my love and skills for
                  DYI-ing.
                </p>
              </template>
            </time-line-item>

            <time-line-item inverted badge-type="success">
              <template #logo>
                <img src="img/logos/vrije-universiteit.png" />
              </template>
              <template #header>
                <div class="badge-vu">
                  <h6>
                    <font-awesome-icon
                      icon="user-graduate"
                      fixed-width
                    />2006-2011
                  </h6>
                  <h5 class="badge-title">
                    Bachelor &amp; Master Health Sciences
                  </h5>
                </div>
              </template>
              <template #content>
                <p>
                  I have always been interested in the mystery that is the human
                  body. How does it work? Why do people get sick and how do we
                  cure them? Why do I have 10 fingers and 10 toes, but some
                  people don't? The only thing is: open heart surgeries I can
                  handle, but I can't stand the sight of broken bones. This
                  drove me away from becoming a docter, but with the study
                  Health Sciences at the Vrije Universiteit of the city of
                  Amsterdam, I could still learn all the interesting things
                  about the human body without being grossed out. I ultimately
                  mastered in Public Health Care. And even though my current
                  employment is related to a whole different string of viruses,
                  I'm planning to return to this field of interest someday.
                </p>
              </template>
            </time-line-item>

            <time-line-item badge-type="info">
              <template #logo>
                <img src="img/logos/hageveld.png" />
              </template>
              <template #header>
                <div class="badge-hageveld">
                  <h6>
                    <font-awesome-icon icon="school" fixed-width />1998-2005
                  </h6>
                  <h5 class="badge-title">Pre-university education</h5>
                </div>
              </template>
              <template #content>
                <p>
                  I completed my pre-university education at Atheneum College
                  Hageveld. My study profile was oriented at Science and Health.
                </p>
              </template>
            </time-line-item>

            <time-line-end badge-type="info">
              <template #icon>
                <font-awesome-icon icon="rocket" />
              </template>
            </time-line-end>
          </time-line>
        </div>
      </div>
    </div>

    <div id="interests" class="section section-interests">
      <div class="container">
        <h3 class="title text-center pt-3 mb-5">Things that keep me going</h3>
        <tabs-container
          v-model:value="tabs.pageCategories"
          type="primary"
          tab-content-classes="tab-subcategories"
          square
          centered
          class="row"
        >
          <tab-pane
            label="sports"
            title="sports"
            icon="running"
            fa-icon="running"
            fa-library="fas"
          >
            <div class="col-md-10 ms-auto me-auto">
              <div class="row collections">
                <div class="col-md-6 mb-4">
                  <div class="position-relative mb-4">
                    <h6 class="img-textoverlay text-center">Running</h6>
                    <img
                      src="img/interests/running.webp"
                      alt="Running"
                      class="img-raised"
                    />
                  </div>
                  <div class="position-relative">
                    <h6 class="img-textoverlay text-center">Hiking</h6>
                    <img
                      src="img/interests/hiking.webp"
                      alt="Hiking"
                      class="img-raised"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-4">
                  <div class="position-relative mb-4">
                    <h6 class="img-textoverlay text-center">Skiing</h6>
                    <img
                      src="img/interests/skiing.webp"
                      alt="Skiing"
                      class="img-raised"
                    />
                  </div>
                  <div class="position-relative">
                    <h6 class="img-textoverlay text-center">Cycling</h6>
                    <img
                      src="img/interests/cycling.webp"
                      alt="Cycling"
                      class="img-raised"
                    />
                  </div>
                </div>
              </div>
            </div>
          </tab-pane>

          <tab-pane
            label="music"
            title="music"
            icon="headphones-alt"
            fa-icon="headphones-alt"
            fa-library="fas"
          >
            <div class="col-md-10 ms-auto me-auto">
              <div class="row collections">
                <div class="col-md-6 mb-4">
                  <div class="position-relative mb-4">
                    <h6 class="img-textoverlay text-center">Guitars</h6>
                    <img
                      src="img/interests/guitar-heroes-wall.webp"
                      alt="Guitar Heroes"
                      class="img-raised"
                    />
                  </div>
                  <div class="position-relative">
                    <h6 class="img-textoverlay text-center">Indie</h6>
                    <img
                      src="img/interests/easy-listening-wall.webp"
                      alt="Easy Listening"
                      class="img-raised"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="position-relative mb-4">
                    <h6 class="img-textoverlay text-center">Einzelgangers</h6>
                    <img
                      src="img/interests/einzelgangers-wall.webp"
                      alt="Einzelgangers"
                      class="img-raised"
                    />
                  </div>
                  <div class="position-relative">
                    <h6 class="img-textoverlay text-center">Electronic</h6>
                    <img
                      src="img/interests/electronic-wall.webp"
                      alt="Electronic"
                      class="img-raised"
                    />
                  </div>
                </div>
              </div>
            </div>
          </tab-pane>

          <tab-pane
            label="drinks"
            title="drinks"
            icon="glass-cheers"
            fa-icon="glass-cheers"
            fa-library="fas"
          >
            <div class="col-md-10 ms-auto me-auto">
              <div class="row collections">
                <div class="col-md-6 mb-4">
                  <div class="position-relative mb-4">
                    <h6 class="img-textoverlay text-center">Craft Beer</h6>
                    <img
                      src="img/interests/craft-beer.webp"
                      alt="Craft beer"
                      class="img-raised"
                    />
                  </div>
                  <div class="position-relative">
                    <h6 class="img-textoverlay text-center">Vino</h6>
                    <img
                      src="img/interests/vino.webp"
                      alt="Vino"
                      class="img-raised"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-4">
                  <div class="position-relative mb-4">
                    <h6 class="img-textoverlay text-center">Whiskey</h6>
                    <img
                      src="img/interests/whiskey.webp"
                      alt="Whiskey"
                      class="img-raised"
                    />
                  </div>
                  <div class="position-relative">
                    <h6 class="img-textoverlay text-center">Say Cheese!</h6>
                    <img
                      src="img/interests/cheese.webp"
                      alt="Cheese"
                      class="img-raised"
                    />
                  </div>
                </div>
              </div>
            </div>
          </tab-pane>
        </tabs-container>
      </div>
    </div>
    <div class="separator-line" />
    <div
      id="spotify"
      class="section section-spotify"
      style="
        background-image: url(&quot;../img/backgrounds/foggy-forest.webp&quot;);
      "
    >
      <div class="container">
        <h3 class="title text-center pt-3 mb-5">Spotify Corner</h3>
        <h5 class="description text-center">
          Listen to some of my favorite tunes.
        </h5>
        <div class="row">
          <div class="col-md-4">
            <card-component type="blog" class="spotify-card-cosy" raised>
              <h6 class="category-social text-center">Cosy &amp; cheerfull</h6>
              <iframe
                src="https://open.spotify.com/embed/playlist/73tvIo5vCPwXeBpX4yfjqE"
                loading="lazy"
                frameborder="0"
                width="100%"
                height="380"
                allowtransparency="true"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              />
            </card-component>
          </div>
          <div class="col-md-4">
            <card-component type="blog" class="spotify-card-happy" raised>
              <h6 class="category-social text-center">Happy tunes</h6>
              <iframe
                src="https://open.spotify.com/embed/playlist/6fkPQjSQ2Rw8x4pT1jnzZH"
                loading="lazy"
                frameborder="0"
                width="100%"
                height="380"
                allowtransparency="true"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              />
            </card-component>
          </div>
          <div class="col-md-4">
            <card-component type="blog" class="spotify-card-chill" raised>
              <h6 class="category-social text-center">Chill sessions</h6>
              <iframe
                src="https://open.spotify.com/embed/playlist/6JgV7T5a9XZQqzjV0AiCXR"
                loading="lazy"
                frameborder="0"
                width="100%"
                height="380"
                allowtransparency="true"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              />
            </card-component>
          </div>
        </div>
        <h5 class="description text-white text-center">
          Or pick one of my favorite genres.
        </h5>
        <div class="row">
          <div class="col-md-4">
            <card-component type="blog" class="spotify-card-alt" raised>
              <h6 class="category-social text-center">Alt &amp; indie</h6>
              <iframe
                src="https://open.spotify.com/embed/playlist/6IsmleZaSscMsn8R1iR731"
                loading="lazy"
                frameborder="0"
                width="100%"
                height="380"
                allowtransparency="true"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              />
            </card-component>
          </div>
          <div class="col-md-4">
            <card-component type="blog" class="spotify-card-heavy" raised>
              <h6 class="category-social text-center">Heavy</h6>
              <iframe
                src="https://open.spotify.com/embed/playlist/3ND7tlbM443PYQzdSZEfRu"
                loading="lazy"
                frameborder="0"
                width="100%"
                height="380"
                allowtransparency="true"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              />
            </card-component>
          </div>
          <div class="col-md-4">
            <card-component type="blog" class="spotify-card-prog" raised>
              <h6 class="category-social text-center">Prog</h6>
              <iframe
                src="https://open.spotify.com/embed/playlist/01mI47WGSzP6wm26SpsG3o"
                loading="lazy"
                frameborder="0"
                width="100%"
                height="380"
                allowtransparency="true"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              />
            </card-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Tabs
import TabsContainer from "@/components/Tabs/TabsContainer";
import TabPane from "@/components/Tabs/TabPane";

// TimeLine
import TimeLine from "@/components/TimeLine/TimeLine.vue";
import TimeLineEnd from "@/components/TimeLine/TimeLineEnd.vue";
import TimeLineItem from "@/components/TimeLine/TimeLineItem.vue";

export default {
  name: "ProfileView",
  bodyClass: "profile-page",
  components: {
    TabsContainer,
    TabPane,
    TimeLine,
    TimeLineEnd,
    TimeLineItem
  },
  setup() {
    return {
      activeName: "first",
      tabs: {
        pageCategories: "sports"
      }
    };
  }
};
</script>
<style></style>
