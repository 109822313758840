<template>
  <nav :class="classes" class="navbar navbar-expand-lg">
    <div class="container">
      <div class="navbar-translate">
        <slot v-bind="slotData"></slot>
        <nav-bar-toggle-button
          :toggled="showMenu"
          @click.stop="toggle"
        ></nav-bar-toggle-button>
      </div>
      <div
        v-if="$slots['navbar-menu'] || $slots['navbar-menu']"
        id="navigation"
        class="collapse navbar-collapse show"
        data-color="orange"
      >
        <ul v-click-outside="close" class="navbar-nav" :class="menuClasses">
          <slot name="navbar-menu" v-bind="slotData"></slot>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import NavBarToggleButton from "./NavBarToggleButton";

export default {
  name: "NavBar",
  components: {
    NavBarToggleButton
  },
  provide() {
    return {
      closeNavbar: this.close
    };
  },
  props: {
    transparent: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: "relative"
    },
    menuClasses: {
      type: [String, Object, Array],
      default: ""
    },
    colorOnScroll: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info"
        ].includes(value);
      }
    },
    navMenuClasses: {
      type: String,
      default: ""
    },
    menuImage: {
      type: String,
      default: ""
    },
    expand: {
      type: [String, Boolean],
      default: "lg"
    }
  },
  data() {
    return {
      showMenu: false,
      extraNavClasses: "",
      currentScrollValue: 0
    };
  },
  computed: {
    slotData() {
      return {
        toggle: this.toggle,
        open: this.open,
        close: this.close,
        isToggled: this.showMenu
      };
    },
    classes() {
      let color = `bg-${this.type}`;
      let navPosition = `navbar-${this.position}`;
      let colorOnScrollTransparent =
        this.colorOnScroll && this.currentScrollValue < this.colorOnScroll;

      if (this.position === "fixed") {
        navPosition = "fixed-top";
      }

      return [
        { "navbar-transparent": this.transparent || colorOnScrollTransparent },
        { [color]: !this.transparent && this.colorOnScroll === 0 },
        this.expand ? `navbar-expand-${this.expand}` : "",
        navPosition,
        this.extraNavClasses
      ];
    }
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    setNav(value) {
      let htmlClasses = document.querySelector("html").classList;
      if (value) {
        htmlClasses.add("nav-open");
      } else {
        htmlClasses.remove("nav-open");
      }
      let isOpen = htmlClasses.contains("nav-open");
      let eventToTrigger = isOpen ? "open" : "close";
      this.showMenu = isOpen;
      this.$emit(eventToTrigger);
    },
    toggle() {
      this.setNav(!this.showMenu);
    },
    open() {
      this.setNav(true);
    },
    close() {
      if (this.showMenu) {
        this.setNav(false);
      }
    },
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `bg-${this.type}`;
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
        }
      }
    }
  }
};
</script>
<style></style>
