<template>
  <li class="timeline-end">
    <div
      v-if="$slots.icon"
      class="timeline-badge-icon timeline-end-icon"
      :class="badgeType"
    >
      <slot name="icon" />
    </div>
    <div v-if="$slots.logo" class="timeline-badge-logo timeline-end-logo">
      <slot name="logo" />
    </div>
  </li>
</template>
<script>
export default {
  name: "TimeLineEnd",
  props: {
    badgeType: {
      type: String,
      default: "success"
    }
  }
};
</script>
<style></style>
