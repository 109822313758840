// Import internal components
import GlobalComponents from "./globalComponents"; // All Vue app components
import GlobalDirectives from "./globalDirectives"; // Registered directives
import GlobalIcons from "./globalIcons"; // Font Awesome Icons
import GlobalMixins from "./globalMixins"; // All mixins

// Internal styling
import "@/assets/sass/theme-kit.scss"; // All portal styling

// Import external components
import "element-plus/theme-chalk/index.css"; // Element Plus styling
import "bootstrap"; // Import bootstrap JS

export default {
  install(app) {
    app.use(GlobalComponents);
    app.use(GlobalDirectives);
    app.use(GlobalIcons);
    app.use(GlobalMixins);
  }
};
