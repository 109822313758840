<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div class="copyright">
        Copyright &copy; {{ year }} | Created by Thijmen Duijve | securityme.nl
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
