<template>
  <router-view name="header" />
  <div class="wrapper">
    <router-view />
  </div>
  <router-view name="footer" />
</template>
<script>
export default {
  name: "App"
};
</script>
