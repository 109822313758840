<template>
  <card-component class="card-timeline" plain>
    <ul class="timeline" :class="{ 'timeline-simple': type === 'simple' }">
      <slot />
    </ul>
  </card-component>
</template>
<script>
export default {
  name: "TimeLine",
  props: {
    type: {
      type: String,
      default: ""
    }
  }
};
</script>
<style></style>
